<template>
  <section class="container">
    <div>
      <heading-layout :heading="'PRIVACY POLICY'">
        <div class="content-container">
          <!-- block  -->
          <div class="block">
            <h3>Privacy Policy</h3>
            <p>
              Property Chowk ("we", "us" or "our") is committed to protecting your privacy. This Privacy Policy explains
              how your personal information is collected, used and disclosed by Property chowk. This Privacy Policy
              applies to our website and its associated subdomains (collectively, our “Service”) alongside our Property
              chowk application. By accessing or using our Service, you signify that you have read, understood and agree
              to our collection, storage, use and disclosure of your personal information as described in this Privacy
              Policy and our Terms of Service. Definitions and key terms

              To help explain things as clearly as possible in this Privacy Policy, whenever any of these terms are
              referenced, are strictly defined as:

            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Cookie:</h3>
            <p>
              A small amount of data generated by a website and saved by your web browser. It is used to identify your
              browser, provide analytics and remember information about you such as your language preferences or login
              information.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Country:</h3>
            <p>
              where Property chowk or Property chowk owners/founders are based, in this case Pakistan
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Customer:</h3>
            <p>
              means the business, organization or individual who registers to use the Property chowk Service to manage
              the relationship with your consumers or users of the Service.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Device:</h3>
            <p>
              any internet-connected device such as a phone, tablet, computer, or other device that can be used to visit
              the Property and use the Services.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>IP address:</h3>
            <p>
              Every device connected to the Internet is assigned a number called an Internet Protocol (IP) address.
              These numbers are usually assigned in geographic blocks. An IP address can often be used to identify the
              location from which a device connects to the Internet.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Personnel:</h3>
            <p>
              means individuals who are employed by Property chowk or who are under contract to perform a service on
              behalf of either party.
              Personal data: any information which directly, indirectly or in connection with other information -
              including a personal identification number - allows the identification or identifiability of a natural
              person.

            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Service:</h3>
            <p>
              means the service provided by Property chowk as described in the relative conditions (if available) and on
              this platform.
              Third Party Service: means advertisers, contest sponsors, promotional and marketing partners and others
              who provide our content or whose products or services we believe may be of interest to you.

            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Website:</h3>
            <p>
              Property chowk website, accessible via this URL: www.propertychowk.com You: a person or entity registered
              with Property chowk to use the Services.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>What information do we collect?</h3>
            <p>
              We collect information from you when you visit our website/app, register on our site, place an order,
              subscribe to our newsletter, respond to a survey or fill out a form.
              Name / Username
              Phone numbers

              Email addresses Postal addresses Billing addresses Age

              Password
              We also collect information from mobile devices for a better user experience, although these features are
              completely optional:
              Location (GPS): Location data helps create an accurate representation of your interests, and this can be
              used to deliver more targeted and relevant advertisements to potential customers. Photo Gallery (Images):
              Grant Photo Gallery access allows users to upload any image from their photo gallery, you can safely deny
              photo gallery access for that website/app.

            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>How do we use the information we collect?</h3>
            <p>
              All information we collect from you may be used in one of the following ways:
              To personalize your experience (your information helps us to better respond to your individual needs)
              To improve our website/app (we continually strive to improve our website/app offerings based on the
              information and feedback we receive from you)
              To improve customer service (your information helps us to more effectively respond to your customer
              service requests and support needs)
              To process transactions
              To administer a contest, promotion, survey or other site feature
              To send periodic emails

            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>When does Property Chowk use end user information from third parties?</h3>
            <p>
              Property chowk will collect end user data necessary to provide Property chowk's services to our customers.
              End users may voluntarily provide us with information that they have made available on social media
              websites. If you provide us with such information, we may collect publicly available information from the
              social media websites you have indicated. You can control the amount of information that social media
              websites make public by visiting those websites and changing your privacy settings.

            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>When does Property Chowk use third party customer information?</h3>
            <p>
              We receive certain information from third parties when you contact us. For example, when you submit your
              email address to us to show your interest in becoming a Property chowk customer, we receive information
              from a third party that provides automated fraud detection services to Property chowk. We also
              occasionally collect information made public on social media websites. You can control the amount of
              information that social media websites make public by visiting those websites and changing your privacy
              settings.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Do we share the information we collect with third parties?</h3>
            <p>
              We may share the information we collect, both personal and non-personal, with third parties such as
              advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or
              whose products or services may be of interest to you. We may also share it with our current and future
              affiliates and business partners, and if we are involved in a merger, sale of assets or other business
              reorganization, we may also share or transfer your personal and non-personal information to our
              successors-in-interest.

              We may engage trusted third-party service providers to perform functions and provide services to us, such
              as hosting and maintenance of our servers and website/application, database storage and management. data,
              email management, storage marketing, credit card processing, customer service, and fulfilling orders for
              products and services you may purchase through the Website/App. We will likely share your personal
              information, and possibly some non-personal information, with these third parties to enable them to
              perform these services for us and for you. We may share portions of our log file data, including IP
              addresses, for analytics purposes with third parties such as web analytics partners, application
              developers, and advertising networks. If your IP address is shared, it may be used to estimate general
              location and other technologies such as connection speed, whether you visited the website/app in a shared
              location and device type used to visit the website/app. They may aggregate information about our
              advertisements and what you see on the website/app, and then provide auditing, research and reporting for
              us and our advertisers. We may also disclose personal and non-personal information about you to government
              or law enforcement officials or private parties if we, in our sole discretion, believe it is necessary or
              appropriate to respond to complaints, legal proceedings (including subpoenas), to protect our rights and
              interests or those of a third party, the safety of the public or any person, to prevent or stop any
              illegal, unethical or actionable activity , or to otherwise comply with applicable court orders, laws,
              rules and regulations.

            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Where and when is information collected from customers and end users?</h3>
            <p>
              Property Chowk will collect the personal information you submit to us. We may also receive personal
              information about you from third parties as described above.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>How do we use your email address?</h3>
            <p>
              By submitting your email address on this website/app, you agree to receive emails from us. You can
              unsubscribe from any of these mailing lists at any time by clicking on the unsubscribe link or other
              unsubscribe option included in the respective email. We only send emails to people who have given us
              permission to contact them, either directly or through a third party. We don't send unsolicited commercial
              email because we hate spam as much as you do. By submitting your email address, you also agree to allow us
              to use your email address for customer audience targeting on sites like Facebook, where we display
              personalized advertisements to specific people who have opted in to receive communications from us. Email
              addresses submitted only through the order processing page will be used for the sole purpose of sending
              you information and updates pertaining to your order. If, however, you have provided the same email to us
              by another method, we may use it for any of the purposes set out in this policy. Note: If at any time you
              would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at
              the bottom of each email.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>How long do we keep your information?</h3>
            <p>
              We retain your information only as long as we need it to provide you with property information and fulfill
              the purposes outlined in this policy. This is also the case for anyone with whom we share your information
              and who performs services on our behalf. When we no longer need to use your information and no longer need
              to retain it to comply with our legal or regulatory obligations, we will delete it from our systems or
              de-identify it so that you cannot be identified.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>How do we protect your information?</h3>
            <p>
              We implement a variety of security measures to maintain the safety of your personal information when you
              place an order or enter, submit, or access your personal information. We offer the use of a secure server.
              All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then
              encrypted into our payment gateway provider database to be accessed only by authorized persons with
              special access rights to such systems, and are required to keep the information confidential. After a
              transaction, your private information (credit cards, social security numbers, financial data, etc.) is
              never kept on file. We cannot, however, ensure or warrant the absolute security of any information you
              transmit to Property chowk or guarantee that your information on the Service cannot be accessed,
              disclosed, altered or destroyed by a breach of any of our physical means. , techniques or managerial
              guarantees
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Could my information be transferred to other countries?</h3>
            <p>
              Property Chowk is incorporated in Pakistan. Information collected through our website, through direct
              interactions with you or through the use of our support services may be transferred from time to time to
              our offices or staff, or to third parties, located in worldwide, and may be accessed and hosted anywhere
              in the world, including countries that may not have laws of general application regulating the use and
              transfer of such data. To the fullest extent permitted by applicable law, by using any of the above, you
              voluntarily consent to the cross-border transfer and hosting of such information.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Is the information collected through the Property chowk service secure?</h3>
            <p>
              We take precautions to protect the security of your information. We have physical, electronic and
              managerial procedures to help safeguard, prevent unauthorized access, maintain data security and use your
              information correctly. However, neither people nor security systems are infallible, including encryption
              systems. Additionally, people may commit intentional crimes, make mistakes, or not follow policies.
              Therefore, although we use reasonable efforts to protect your personal information, we cannot guarantee
              its absolute security. If applicable law imposes an undeniable obligation to protect your personal
              information, you agree that willful misconduct shall be the standard used to measure our compliance with
              that obligation.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Can I update or correct my information?</h3>
            <p>
              The rights you have to request updates or corrections to information collected by Property chowk depend on
              your relationship with Property chowk. Staff may update or correct their information as set forth in the
              company's internal employment policies.
              Customers have the right to request restriction of certain uses and disclosures of personally identifiable
              information as follows. You may contact us to (1) update or correct your personally identifiable
              information, (2) change your preferences regarding the communications and other information you receive
              from us, or (3) delete the personally identifiable information. stored about you on our systems (subject
              to the following paragraph), by canceling your account. Such updates, corrections, modifications and
              deletions will not affect any other information we









              maintain or information we provided to third parties under this Privacy Policy prior to such update,
              correction, modification or deletion. To protect your privacy and security, we may take reasonable steps
              (such as requiring a unique password) to verify your identity before granting you profile access or making
              corrections. You are responsible for maintaining the confidentiality of your unique password and account
              information at all times.

              You should be aware that it is not technically possible to delete from our system every record of the
              information you have provided to us. The need to back up our systems to protect information from
              accidental loss means that a copy of your information may exist in a non-erasable form that will be
              difficult or impossible for us to locate. Upon receipt of your request, all personal information stored in
              databases actively used by us and in other readily searchable media will be updated, corrected, amended or
              deleted, as appropriate, as soon as possible and to the extent reasonably and technically feasible.

              If you are an end user and wish to update, delete or receive information we have about you, you can do so
              by contacting the organization of which you are a customer.

            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Personal</h3>
            <p>
              If you are a Property Chowk worker or applicant, we collect information that you voluntarily provide to
              us. We use the information collected for human resources purposes to administer employee benefits and
              screen applicants.

              You may contact us to (1) update or correct your information, (2) change your preferences regarding the
              communications and other information you receive from us, or (3) receive a record of the information we
              have about you. Such updates, corrections, modifications and deletions will not affect any other
              information we maintain or information we provided to third parties under this Privacy Policy prior to
              such update, correction, modification or deletion.

            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Sale of business</h3>
            <p>
              We reserve the right to transfer information to a third party in the event of a sale, merger or other
              transfer of all or substantially all of the assets of Property Chowk or any of its Affiliates (as defined
              herein ), or that part of Property chowk or any of its affiliates to which the service relates, or in the
              event that we discontinue our business or file or have filed against us a petition for bankruptcy,
              reorganization or similar proceeding , provided that the third party agrees to abide by the terms of this
              Privacy Policy.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Affiliates</h3>
            <p>
              We may disclose information (including personal information) about you to our affiliates. For the purposes
              of this Privacy Policy, “affiliate” means any person or entity that directly or indirectly controls, is
              controlled by, or is under common control with Property Chowk
              , whether by ownership or otherwise. Any information about you that we provide to our Affiliates will be
              treated by those Affiliates in accordance with the terms of this Privacy Policy.

            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Applicable right</h3>
            <p>
              This Privacy Policy is governed by the laws of Pakistan, without regard to its conflict of law provisions.
              You consent to the exclusive jurisdiction and venue of the courts in any action or dispute arising between
              the parties under or in connection with this Privacy Policy, except for those persons who may be entitled
              to bring claims under Privacy Shield or Swiss-U.S. Framework.









              The laws of Pakistan, excluding its conflict of laws rules, shall govern this Agreement and your use of
              the Website/App. Your use of the Website/App may also be subject to other local, state, national or
              international laws.
              By using Property chowk or contacting us directly, you signify your acceptance of this Privacy Policy. If
              you do not agree to this Privacy Policy, you should not engage with our website or use our services. Your
              continued use of the Website, direct engagement with us, or posting of changes to this Privacy Policy that
              do not materially affect our use or disclosure of your Personal Information will mean that you accept
              those changes.

            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Your consent</h3>
            <p>
              We've updated our privacy policy to give you full transparency about what's set when you visit our site
              and how it's used. By using our website/app, registering an account or making a purchase, you hereby
              consent to our privacy policy and agree to its terms.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Links to other websites</h3>
            <p>
              This Privacy Policy applies only to the Services. The Services may contain links to other websites not
              operated or controlled by Property chowk. We are not responsible for the content, accuracy or opinions
              expressed on such websites, and such websites are not investigated, monitored or checked by us for
              accuracy or completeness. Remember that when you use a link to move from the Services to another website,
              our Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including
              those linked on our Platform, is subject to that website's own rules and policies. These third parties may
              use their own cookies or other methods to collect information about you.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Advertising</h3>
            <p>
              This website/application may contain third party advertisements and links to third party sites. Property
              Chowk makes no representations as to the accuracy or appropriateness of any of the information contained
              in such advertisements or sites and accepts no responsibility for the conduct or content of such
              advertisements and sites and offers made by third parties. . . Advertising keeps chowk property and many
              websites and services you use free. We work hard to make sure ads are safe, unobtrusive, and as relevant
              as possible.
              Third party advertisements and links to other sites where goods or services are advertised are not
              endorsements or recommendations by Property chowk of any third party sites, goods or services. Property
              chowk takes no responsibility for the content of any of the advertisements, the promises made or the
              quality/reliability of the products or services offered in any advertisements.

            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Advertising cookies</h3>
            <p>
              These cookies collect information over time about your online activity on the Website/App and other online
              services to make online advertisements more relevant and effective for you. This is called interest-based
              advertising. They also perform functions such as preventing the same advertisement from constantly
              reappearing and ensuring that advertisements are displayed correctly for advertisers. Without cookies, it
              is very difficult for an advertiser to reach their audience, or to know how many advertisements have been
              served and how many clicks they have received cookies.

              Property chowk uses "cookies" to identify which areas of our website you have visited. A cookie is a small
              piece of data stored on your computer or mobile device by your web browser. We use cookies to improve the
              performance and functionality of our website/application, but they are not essential to their use.
              However, without these cookies certain features such as videos may become unavailable or you may have to
              re-enter your login details each time you visit the website/app as we would not be able to remember that
              you previously logged in. Most web browsers can be configured to disable the use of cookies. However, if
              you disable cookies, you may not be able to access features on our website properly or at all. We never
              place personally identifiable information in cookies

            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Blocking and disabling cookies and similar technologies</h3>
            <p>
              Wherever you are, you can also configure your browser to block cookies and similar technologies, but this
              action may block our essential cookies and prevent our website from working properly, and you may not be
              able to fully use all of its features. and services. You should also be aware that you may also lose
              certain saved information (e.g. saved login information, site preferences) if you block cookies on your
              browser. Different browsers provide you with different controls. Disabling a cookie or a category of
              cookies does not delete the cookie from your browser, you will have to do this yourself from your browser,
              you should visit your browser's help menu for more information.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Remarketing services</h3>
            <p>
              We use remarketing services. What is remarketing? In digital marketing, remarketing (or retargeting) is
              the practice of serving advertisements across the Internet to people who have previously visited your
              website. This allows your business to appear to "follow" people across the internet by showing ads on the
              websites and platforms they use most.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Payment details</h3>
            <p>
              In relation to any credit card or other payment processing details you provide to us, we are committed to
              ensuring that such confidential information is stored as securely as possible.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Children's Privacy</h3>
            <p>
              We do not address anyone under the age of 13. We do not knowingly collect personally identifiable
              information from anyone under the age of 13. If you are a parent or guardian and you know that your child
              has provided us with personal data, please contact us. If we learn that we have collected personal data
              from someone under the age of 13 without verification of parental consent, we take steps to delete that
              information from our servers.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Changes to our privacy policy</h3>
            <p>
              We may change our service and policies, and we may make changes to this privacy policy so that they
              accurately reflect our service and policies. Unless otherwise required by law, we will notify you (for
              example, through our Service) before we make changes to this Privacy Policy and give you an opportunity to
              review them before they become effective. Thereafter, if you continue to use the Service, you will be
              bound by the updated Privacy Policy. If you do not wish to accept this Privacy Policy or any updates, you
              may delete your account.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Third party services</h3>
            <p>
              We may display, include or otherwise make available third party content (including data, information,
              applications and other product services) or provide links to third party websites or services ("Third
              Party Services ").
              You acknowledge and agree that Property chowk shall not be responsible for any Third-Party Services,
              including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency,
              quality, or any other aspect of these. P does not assume or assume any liability to you or any other
              person or entity for any Third Party Services.

              Third-party services and related links are provided for your convenience only and you access and use them
              entirely at your own risk and subject to the terms and conditions of such third parties.

              Facebook Pixel
              The Facebook pixel is an analytics tool that allows you to measure the effectiveness of your advertising
              by understanding the actions people take on your website. You can use the pixel to: Ensure your ads are
              shown to the right people. The Facebook pixel may collect information from your device when you use the
              service. The Facebook pixel collects information held in accordance with its privacy policy

            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Tracking Technologies</h3>
            <p>
              Google Maps API Google Maps API is a robust tool that can be used to create a custom map, searchable map,
              recording functions, view live data synchronization with location, plan routes or create a mashup to name
              a few. Google Maps API may collect information from you and your device for security purposes.
              Google Maps API collects information held in accordance with its privacy policy

              CookiesWe use cookies to improve the performance and functionality of our $ platform but are not essential
              to their use. However, without these cookies certain features such as videos may become unavailable or you
              may need to re-enter your login details each time you visit the $platform as we would not be able to
              remember that you logged in. previously.

              Local StorageLocal storage, sometimes referred to as DOM storage, provides web applications with methods
              and protocols for storing client-side data. Web storage supports persistent data storage, similar to
              cookies but with significantly improved capacity and no information stored in the HTTP request header.

              Sessions$el number uses "Sessions" to identify the areas of our website that you have visited. A session
              is a small piece of data stored on your computer or mobile device by your web browser.

            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Information on the General Data Protection Regulation (GDPR)</h3>
            <p>
              We may collect and use information about you if you are from the European Economic Area (EEA), and in this
              section of our Privacy Policy we will explain exactly how and why this data is collected, and how we store
              this data. under protection against reproduction or incorrect use.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>What is GDPR?</h3>
            <p>
              GDPR is a European privacy and data protection law that regulates how EU residents' data is protected by
              businesses and strengthens EU residents' control over their personal data.
              GDPR is relevant for any business operating globally and not just for EU-based businesses and EU
              residents. Our customers' data is important no matter where it is located, which is why we have
              implemented GDPR controls as a baseline standard for all of our operations globally.

            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>What is personal data?</h3>
            <p>
              Any data relating to an identifiable or identified person. The GDPR covers a wide range of information
              that can be used alone or in combination with other pieces of information to identify a person. Personal
              data goes beyond a person's name or email address. Some examples include financial information, political
              opinions, genetic data, biometric data, IP addresses, physical address, sexual orientation and ethnic
              origin.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>The Data Protection Principles include requirements such as:</h3>
            <p>
              The personal data collected must be treated in a fair, lawful and transparent manner and must only be used
              in a way that a person could reasonably expect.
              Personal data should only be collected to fulfill a specific purpose and should only be used for that
              purpose. Organizations must specify why they need the personal data when they collect it. Personal data
              should not be kept longer than necessary to fulfill its purpose.
              Individuals covered by the GDPR have the right to access their own personal data. They can also request a
              copy of their data and have their data updated, deleted, restricted, or moved to another organization.

            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Why is GDPR important?</h3>
            <p>
              The GDPR adds new requirements regarding how companies must protect the personal data of individuals that
              they collect and process. It also raises the compliance stakes by increasing enforcement and imposing
              larger fines for violations. Beyond these facts, it is simply the right thing to do. At Property chowk, we
              firmly believe that the privacy of your data is very important and we have already implemented strong
              security and privacy practices that go beyond the requirements of this new regulation.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Individual rights of data subjects - access, portability and deletion of data</h3>
            <p>
              We are committed to helping our customers comply with the data subject rights requirements of the GDPR.
              Property Chowk processes or stores all personal data with fully vetted and DPA-compliant vendors. We store
              all conversations and personal data for up to 6 years unless your account is deleted. In this case, we
              dispose of all data in accordance with our Terms of Use and Privacy Policy, but we will not retain it for
              longer than 60 days.
              We realize that if you work with EU customers, you need to be able to provide them with the ability to
              access, update, retrieve and delete personal data. We have you! We were set up from the start as
              self-service and have always given you access to your data and your customers' data. Our customer support
              team is here to answer all your questions regarding the use of the API.

            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>Contact us</h3>
            <p>
              Do not hesitate to contact us if you have any questions.
              By email: info@propertychowk.com
            </p>
          </div>
          <!-- block  -->
         
        </div>
      </heading-layout>
    </div>
  </section>
</template>

<script>
import HeadingLayout from "../common/headingLayout.vue";
export default {
  name: "PrivacyPolicy",
  components: {
    HeadingLayout,
  },
};
</script>

<style>

</style>