<template>
  <div class="plan-container">
    <div>
      <img src="../../assets/images/masterPlan.png" />
    </div>
    <div>
      <img src="../../assets/images/plan.png" />
    </div>
  </div>
</template>

<script>
export default {
name: "PlaneMap"
}
</script>

<style>
.plan-container{
    padding: 40px 0;
}
.plan-container div{
    margin: 10px 0;
}
 .plan-container img{
    width: 100%;
  }
</style>