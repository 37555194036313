<template>
  <section class="container">
    <div>
      <heading-layout :heading="'TERMS & CONDITIONS'">
        <div class="content-container">
          <!-- block  -->
          <div class="block">
            <h3>LOREM IPSUM</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
              tortor lacus, fermentum vitae sagittis ultrices, imperdiet ac
              eros. Morbi lobortis lectus nec neque laoreet tincidunt. Praesent
              iaculis congue laoreet. Quisque ornare vulputate metus, et mollis
              elit aliquet nec. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Donec in blandit lorem. Nulla in est non metus
              faucibus ultricies id rhoncus dui. Nunc egestas aliquam erat, eu
              fringilla tortor gravida ac.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>LOREM IPSUM</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
              tortor lacus, fermentum vitae sagittis ultrices, imperdiet ac
              eros. Morbi lobortis lectus nec neque laoreet tincidunt. Praesent
              iaculis congue laoreet. Quisque ornare vulputate metus, et mollis
              elit aliquet nec. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Donec in blandit lorem. Nulla in est non metus
              faucibus ultricies id rhoncus dui. Nunc egestas aliquam erat, eu
              fringilla tortor gravida ac.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>LOREM IPSUM</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
              tortor lacus, fermentum vitae sagittis ultrices, imperdiet ac
              eros. Morbi lobortis lectus nec neque laoreet tincidunt. Praesent
              iaculis congue laoreet. Quisque ornare vulputate metus, et mollis
              elit aliquet nec. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Donec in blandit lorem. Nulla in est non metus
              faucibus ultricies id rhoncus dui. Nunc egestas aliquam erat, eu
              fringilla tortor gravida ac.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>LOREM IPSUM</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
              tortor lacus, fermentum vitae sagittis ultrices, imperdiet ac
              eros. Morbi lobortis lectus nec neque laoreet tincidunt. Praesent
              iaculis congue laoreet. Quisque ornare vulputate metus, et mollis
              elit aliquet nec. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Donec in blandit lorem. Nulla in est non metus
              faucibus ultricies id rhoncus dui. Nunc egestas aliquam erat, eu
              fringilla tortor gravida ac.
            </p>
          </div>
          <!-- block  -->
          <!-- block  -->
          <div class="block">
            <h3>LOREM IPSUM</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
              tortor lacus, fermentum vitae sagittis ultrices, imperdiet ac
              eros. Morbi lobortis lectus nec neque laoreet tincidunt. Praesent
              iaculis congue laoreet. Quisque ornare vulputate metus, et mollis
              elit aliquet nec. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Donec in blandit lorem. Nulla in est non metus
              faucibus ultricies id rhoncus dui. Nunc egestas aliquam erat, eu
              fringilla tortor gravida ac.
            </p>
          </div>
          <!-- block  -->
        </div>
      </heading-layout>
    </div>
  </section>
</template>

<script>
import HeadingLayout from "../common/headingLayout.vue";
export default {
  name: "TermsConditions",
  components: {
    HeadingLayout,
  },
};
</script>

<style>
</style>